.body {
  padding: 20px !important;
  div[class='ant-image css-dev-only-do-not-override-rxgxro'] {
    width: 100%;
  }

  ul li button {
    box-shadow: 0px 0px 4px 0px #000000bd;
  }

  ul li:not([class='slick-active']) button {
    box-shadow: 0px 0px 3px 2px #000000bd;
  }
}

.attachment {
  width: 100%;
  height: 200px !important;
  object-fit: cover;
}
