@import "../../../scss/variables/colors.module.scss";

.modal {
  

  .textarea{
    border-radius: 3px;
  }

}
