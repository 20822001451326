@import '../../scss/variables/colors.module.scss';

@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .topBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    animation: slideDown 0.4s ease-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .bannerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 10px 20px;
  }

  .bannerClose {
    border: 0;
  }
  
  .bannerContent button:hover {
    background: #f0f0f0;
  }