@import '../../../../scss/variables/colors.module.scss';

.modalWrap {
  
  div[class*=ant-modal-body] {

    div[class*=ant-tabs-nav] {
      margin: 2px 2px 0 2px !important;
    };
    
    div[class*=ant-tabs-tab-active] {
      border-color: $primary100 !important;
      border-bottom: 1px solid transparent !important;
    };
  };

  .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;
    padding: 0 24px;
    min-height: 56px;
    background-color: #ffffff;

    .statusWrap {
      display: flex;
      max-width: 60%;

      .docName {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .dateWrap {
    display: flex;
    align-items: center;
    font-weight: 500;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .acceptIcon {
    padding-left: 15px;
    font-size: 28px;
    color: $statusResolved;
  }

  .notAcceptIcon {
    padding-left: 15px;
    font-size: 28px;
    color: $png;
  }
  
  .rejectIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $powerPoint;
  }

  .nonAccept {
    color: $png;
  }
  
  .accept {
    color: $statusResolved;
  }

  .reject {
    color: $powerPoint;
  }

  .date {
    padding: 0px 10px 0px 25px;
  }

  .dateValue {
    min-width: 30px;
  }

  .tabs {
    border: 1px solid $borderColor;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: white;
  }
}
