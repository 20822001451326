@import "../../../scss/variables/colors.module.scss";

.card {
  overflow: hidden;
  margin: 15px 0;

  div[class*=ant-card-head-title] {
    color: $textcolor !important;
  }
}

.dragger {
  display: block;
  max-width: 600px;
  overflow: hidden;
  margin: 30px auto;

  div[class='ant-upload-drag-container'] {
    padding: 20px;
  }
}

.modalWrap {
  max-width: 800px;
  overflow: hidden;

  div[class*=ant-modal-body] {
    padding: 20px;

    .select {
      width: 100%;

      span[class*=ant-select-selection-item] {
        color: $primary100;
        font-weight: 500;
      }
    }

    .form {
      padding: 15px 5px 5px 5px;

      label {
        font-weight: 600;
      }
    }

    .fileIcon {
      color: $primary100;
    }
  }
};

.draggerModal {

}

.relatedRoomsWrap {

  .relatedRoomsTitle {
    display: grid;
    font-size: 16px;
    color: $textcolor;
    font-weight: 600;

    .status {
      padding-left: 4px;
      font-size: 15px;
    }
  }
}

.emptyText {
  margin: 10px !important;
}

.statusTermWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;

  .acceptIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $statusResolved;
  }

  .notAcceptIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $png;
  }

  .rejectIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $powerPoint;
  }
}

.nonAccept {
  color: $png;
}

.accept {
  color: $statusResolved;
}

.reject {
  color: $powerPoint;
}

.canEdit {
  grid-template-columns: 5fr 6fr 5fr 1fr;
}

.cantEdit {
  grid-template-columns: 5fr 6fr 5fr;
}

.uploadedWrap {
  width: 100%;
  display: grid;
  gap: 12px;
  padding-top: 10px;
  
  &:not(:last-child) {
    border-bottom: 1px solid $borderColor;
    padding-bottom: 10px;
  }

  .roomName {
    font-weight: 600;
    display: flex;
    align-items: center;
    color: black;
  }

  .roomNameDeleted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $textcolor;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
    height: 65px;
    border-radius: 6px;
    border: 1px solid $borderColor;

    &:hover {
      cursor: pointer;
      background-color: $hover200;
    }

    .cardView {
      font-size: 20px;
      color: $primary100;
      display: flex;
      align-items: center;
  
      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  }

  .deleteButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .deleteTermIcon {
      font-size: 16px;
      color: $red;
    }
  }
}

.exclamationIcon {
  font-size: 22px !important;
  color: $png;
  padding-right: 6px;
}

.titleRow  {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textModal {
  font-size: 16px;
  font-weight: 400;
}

////////ModalRoomTerm
.modalRoomTermWrap {
  
  div[class*=ant-modal-body] {

    div[class*=ant-tabs-nav] {
      margin: 2px 2px 0 2px !important;
    };
    
    div[class*=ant-tabs-tab-active] {
      border-color: $primary100 !important;
      border-bottom: 1px solid transparent !important;
    };
    
  }

  .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;
    padding: 0 24px;
    min-height: 56px;
    background-color: #ffffff;

    .statusWrap {
      display: flex;
      max-width: 60%;

      .docName {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .dateWrap {
    display: flex;
    align-items: center;
    font-weight: 500;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .acceptIcon {
    padding-left: 15px;
    font-size: 28px;
    color: $statusResolved;
  }

  .rejectIcon {
    padding-left: 15px;
    font-size: 28px;
    color: $powerPoint;
  }

  .notAcceptIcon {
    padding-left: 15px;
    font-size: 28px;
    color: $png;
  }

  .date {
    padding: 0px 10px 0px 25px;
  }

  .dateValue {
    min-width: 30px;
  }

  .tabs {
    border: 1px solid $borderColor;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: white;
  }
}

.divider {
  border-top: 1px solid $borderColor;
  padding-top: 15px;
}
