@import "../../scss/variables/colors.module.scss";

.tabWrap {
  background-color: white;
  // border-bottom: 1px solid $borderColor;
  // border-left: 1px solid $borderColor;
  // border-right: 1px solid $borderColor;
  // border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.actionsButton {
  top: -40px;
  right: 7px;
  position: absolute;
}

.container {
  padding: 15px;
}

.tableWrap {
  max-height: calc(100vh - 288px);
}

.withoutButtons {
  height: calc(100vh - 288px);
}

.withButtons {
  height: calc(100vh - 338px);
}

.controlButtons {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin-top: 15px;
   
  button{
    width: 130px;
  }
}

.dropdownItem {
  padding: 4px !important;

  .dropdownButton {
    color: $textcolor;
    width: 100%;
    text-align: start;
  }

  &:not(:first-child) {
    padding-top: 4px !important;
  }
}

.rowWrap {
  display: flex;
  align-items: center;
  text-wrap: wrap;

  span {
    text-wrap: wrap;
  }
}

.acceptIcon {
  padding-right: 5px;
  font-size: 18px;
  color: $statusResolved;
}

.rejectIcon {
  padding-right: 5px;
  font-size: 18px;
  color: $powerPoint;
}

.notAcceptIcon {
  padding-right: 5px;
  font-size: 18px;
  color: $statusProgress;
}

.accept {
  font-weight: 500;
  color: $statusResolved;
}

.reject {
  font-weight: 500;
  color: $powerPoint;
}

.nonAccept {
  font-weight: 500;
  color: $statusProgress;
}

