@import '../../scss/variables/colors.module.scss';

.destroy {
  display: none;
}

// tabs children's panel height
.messanger_tabs div[class='ant-tabs-content ant-tabs-content-left'],
.messanger_tabs div[class='ant-tabs-content ant-tabs-content-left'] div[role='tabpanel'] {
  height: 100%;
  padding-left: 0 !important;
}

.messanger_tabs div[class='ant-tabs-content-holder'] {
  border-color: $borderColor;
  margin-left: 0;
}

.messanger_tabs div[class*='ant-tabs-nav-operations'] {
  display: none !important;
}

.messanger_tab_panel div[role='tablist'] {
  display: flex;
  align-items: center;
  flex: none;
  position: relative;
  width: 100%;
}
.messanger_tab div[class='ant-tabs-tab'],
.messanger_tab div[class='ant-tabs-tab ant-tabs-tab-active'],
.messanger_tab_panel div[class='ant-tabs-tab'],
.messanger_tab_panel div[class='ant-tabs-tab ant-tabs-tab-active'] {
  padding: 0 !important;
  width: 100%;
  margin-left: 0 !important;
  text-align: center;

  div[role='tab'] {
    width: 100%;
  }
}

.messanger {
  width: 100%;
  position: relative;
  height: 100%;
  border-radius: 4px;
  border: 1px solid $borderColor;
  border-left: 0px;
  font-size: 13px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: column;

  ul li button {
    box-shadow: 0px 0px 4px 0px #000000bd;
  }

  ul li:not([class='slick-active']) button {
    box-shadow: 0px 0px 3px 2px #000000bd;
  }

  &_tabs {
    height: 100%;
    width: 100%;

    &_extraAction {
      color: $primary100;
      transition: 0.3s all linear;
      width: 100%;
      height: 52.4px;
      margin-bottom: 0 !important;
      line-height: 52.4px;
      background: #fff;
      border-top: 1px solid $borderColor;
      text-align: center;
      position: absolute;
      bottom: 0.1px;

      &:hover {
        background-color: $primary100;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  &_tab {
    position: relative;
    width: 25%;
    height: 100%;
    border-left: 1px $borderColor solid;
    border-top: 1px $borderColor solid;
    border-bottom: 1px $borderColor solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-top: 16px;

    &_panel {
      overflow: auto;
      height: calc(100% - 50px);
      width: 100%;
    }

    &_item {
      border-bottom: 1px solid $borderColor;
      padding: 8px 12px;
      width: 100%;
      margin-top: 16px;
    }
  }

  &_header {
    height: 70px;
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    border-bottom: 1px solid $borderColor;
    place-items: center;
    padding: 0 20px;

    &_items {
      &:first-child {
        justify-self: flex-start;
      }

      &:nth-child(2) {
        justify-self: flex-start;
        width: 70%;
      }
    }
  }

  &_avatars {
    position: relative;
    height: 32px;
    width: calc(var(--length) * 20px + 12px);
    max-width: 240px;
    cursor: pointer;

    &:hover .messanger_avatar {
      animation: translateTop 0.4s forwards linear;
    }
  }

  &_avatar {
    left: calc(var(--idx) * 20px);
    position: absolute;
    top: 0;
    background-color: #5e5e5e;
    animation-delay: calc(var(--idx) * 0.15s) !important;
  }

  &_settings {
    text-align: right;
  }

  &_title {
    text-align: center;
  }

  &_name {
    font-weight: bold;
  }

  &_date {
    opacity: 0.85;
  }

  &_settingsIcon {
    font-size: 22px;
    cursor: pointer;
    transition: 0.3s all linear;

    &:hover {
      color: $primary100;
    }
  }

  &_body {
    width: 100%;
    max-height: calc(100% - 120px);
    height: calc(100% - 120px);
    padding-right: 8px;
    position: relative;
    overflow: hidden auto;

    div[data-testid='virtuoso-item-list']{
      padding-left: 22px;
      padding-right: 22px;
    }

    div[data-testid='virtuoso-top-item-list']{
      padding-left: 29px;
      padding-right: 22px;
    }

    &::-webkit-scrollbar {
      width: 14px;
    }
  }

  &_bodyMessages {
    max-height: 100%;
  }

  &_chatGroups {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &_dateSection {
    z-index: 999;
    position: sticky;
    top: -15px;
    padding: 2px 5px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.3);
    width: fit-content;
    margin: 5px auto;
    color: #fff;
    overflow: hidden;
  }

  &_send {
    transition: all 1s ease-out;
    padding: 8px;
    border-radius: 1em;
    background-color: #007aff;
    color: #ffffff;
    margin-left: auto;
    position: relative;
    margin-bottom: 3px;
    max-width: 60%;
    width: fit-content;
    word-break: break-all;

    .messanger_messageTime {
      text-align: right;
      color: #b3d7ff;
    }

    .messanger_messageTime {
      display: flex;
      justify-content: flex-end;
      gap: 4px;
    }
  }
  &_loading {
    &:hover {
      cursor: wait;
    }
  }

  &_messageText {
    margin-bottom: 3px;
    word-break: break-word;
    white-space: break-spaces;
  }

  &_messageTime {
    margin-bottom: 0px;
    font-size: 0.85em;
    text-align: right;
  }

  .messanger_send.messanger_first {
    border-top-right-radius: 0px;
    margin-top: 7px;
  }

  .messanger_send.messanger_first::after,
  .messanger_send.messanger_first::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    width: 1.8em;
    height: 1.8em;
    border: 0.5em solid white;
    border-radius: 50%;
    background: #fff;
  }

  .messanger_send.messanger_first::before {
    border-top: none;
    height: 0.9em;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
    background: #007aff;
    border-color: #007aff;
    margin-left: -0.9em;
  }

  &_checked {
    display: flex;
    position: relative;
    padding-top: 2px;
  }

  &_check {
    width: 11px;
    height: 4px;
    transform: rotate(-45deg);
    margin-right: 1px;
    position: relative;
    background-color: #007aff;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: #fff;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &::before {
      content: '';
      display: block;
      background-color: #fff;
      height: 100%;
      width: 1.5px;
      border-top-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:nth-child(1) {
      z-index: 1;
    }

    &:nth-child(2) {
      left: -7px;
      z-index: 0;
    }
  }

  &_checked.messanger_viewed {
    .messanger_check {
      &::after {
        background-color: #fff;
      }

      &::before {
        background-color: #fff;
      }
    }
  }

  &_rejected {
    color: #ff1111;
    cursor: pointer;
  }

  &_recieve {
    transition: all 1s ease-out;
    display: flex;
    gap: 10px;
    margin-bottom: 3px;
    max-width: 60%;
    width: fit-content;
    word-break: break-all;

    svg {
      font-size: 20px;
    }
  }

  .messanger_recieve.messanger_first {
    margin-top: 7px;
  }

  &_recieveAvatar {
    position: relative;
    z-index: 10;
    background: #bebebe !important;
  }

  &_recieveMessage {
    padding: 8px;
    border-radius: 1em;
    background-color: #f2f2f7;
    color: #3b3b3e;
    margin-right: auto;
    position: relative;
    margin-left: 42px;
    max-width: calc(100% - 45px);
    word-wrap: break-word;

    .messanger_messageTime {
      color: #9e9ea1;
    }
  }

  &_userData {
    margin-bottom: 10px;
  }

  &_userName {
    margin-right: 15px;
    font-weight: 700;
  }

  &_userPosition {
    color: #8e8e90;
    font-weight: 450;
  }

  .messanger_recieveMessage.messanger_first {
    border-top-left-radius: 0px;
    margin-left: 0;
  }

  .messanger_recieveMessage.messanger_first::after,
  .messanger_recieveMessage.messanger_first::before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 1.8em;
    height: 1.8em;
    border: 0.5em solid white;
    border-radius: 50%;
    background: #fff;
  }

  .messanger_recieveMessage.messanger_first::before {
    border-top: none;
    height: 0.9em;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
    background: #f2f2f7;
    border-color: #f2f2f7;
    margin-right: -0.9em;
  }

  &_footer {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &_form {
      margin-top: auto;
    }

    &_item {
      position: relative;
      margin: 0;
      width: 100%;
    }
  }

  &_footerIcons {
    font-size: 16px;
    transition: 0.3s all linear;
    cursor: pointer;

  }

  &_left {
    position: absolute;
    left: 32px;
    z-index: 10;
  }

  &_textarea {
    padding: 4px 75px 4px 30px;
    border: 1px solid transparent;
    transition: 0.2s all linear;
    // min-height: 52px !important;

    &::before {
      content: attr(data-placeholder); /* Берем текст из атрибута data-placeholder */
      color: #aaa;
      position: absolute;
      pointer-events: none;
    }

    &:not(:empty)::before {
      content: ''; /* Убираем плейсхолдер, если есть текст */
    }

    &:focus {
      border: 1px solid $primary100;
      border-radius: 4px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_sendingIcons {
    position: absolute;
    right: 20px;
    z-index: 10;

    strong{
      background-color: $primary100;
      padding: 1px 7px;
      border-radius: 7px;
      color: #fff;
      transition: 0.3s all linear;

      &:hover{
        opacity: 0.8;
      }
    }

    svg:first-child {
      transform: translateX(-100%);
    }
  }

  &_tags {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  &_overlay{
    max-width: 500px;
    // background: $primary100;
  }

  &_attachmentIcon{
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 16px;
    cursor: pointer;
  }
}

.reject_buttons {
  width: 100%;

  &:first-child {
    margin-bottom: 10px;
  }
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $borderColor;
  margin-inline: 0px;
  border-radius: 4px;
}

@keyframes translateTop {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40%);
  }

  100% {
    transform: translateY(0);
  }
}

.dropdown {
  min-width: 150px !important;

  li[class*='ant-dropdown-menu-item'] {
    color: $textcolor !important;
    font-weight: 500 !important;
    font-size: 15px !important;

    &:hover {
      color: $primary100 !important;
    }
  }
}

.replyBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $borderColor;
  padding: 0 10px;

  .replyWrap {
    width: calc(100% - 20px);
    padding-bottom: 6px;

    .whom {
      font-weight: 600;
      color: $primary100;
    }

    .reply {
      width: 100%;
      color: $textcolor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .replyClose {
    width: 20px;
  }
}

.repliedWrap {
  padding: 2px 6px;
  border-radius: 6px;
  margin-bottom: 6px;

  &:hover {
    cursor: pointer;
  }

  .repliedAuthor {
    font-weight: 600;
    color: black;
  }
}

.recieve {
  background-color: white;
  color: $textcolor;
  border-left: 4px solid grey;
}

.send {
  background-color: #bbdcff;
  color: $darkertext;
  border-left: 4px solid #003873;
  // background-color: #003873;
}

.borderTop {
  border-top: 1px solid $borderColor !important;
}

.tag {
  color: $primary100;

  &_documentList {
    max-height: 150px;
    overflow: auto;
  }
}

.inputTag{
  color: $primary100;
  border: 1px solid $borderColor;
  background-color: $hover100;
  border-radius: 4px;
}

.messageTag{
  color: #fff !important;
  border: 1px solid $borderColor;
  background-color: $primary100;
  border-radius: 4px;
}

.attachment_img{
  width: 250px;
  height: 300px;
  display: block;
  img{
    object-fit: cover;
    height: 100% !important;
  }
  margin-bottom: 4px;
}

.downloadIcon{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  background-color: #00000075;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}